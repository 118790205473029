import React from "react";

import posed, { PoseGroup } from "react-pose"

const Transition = posed.div({})

export const replaceComponentRenderer = ({ props, ...other }) => {
    const { component } = props.pageResources
    return (
      <PoseGroup>
        <Transition key={props.location.pathname}>
          {React.createElement(component, props)}
        </Transition>
      </PoseGroup>
    )
}